import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import * as XLSX from 'xlsx';
import { SkillItemDialogComponent } from './skill-item-dialog/skill-item-dialog.component';
import { StaffDetailDialogComponent } from './staff-detail-dialog/staff-detail-dialog.component';
import { MapDialogComponent } from '../utility/map-dialog/map-dialog.component';
import { BaseComponent } from '../base.component';
import { AddressPrintDialogComponent } from '../common/address-print-dialog/address-print-dialog.component';
import * as moment from 'moment';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.less']
})

export class StaffsComponent extends BaseComponent implements OnInit {
  // 清掃員一覧テーブルのデータソース
  dataSource = new MatTableDataSource();

  selection = new SelectionModel(true, []);

  // エクスポート用データ
  exportData = [];

  // ローディング
  nowLoading = true;

  // エクスポート用ローディング
  loadingExport = true;

  // 清掃員名　検索
  filterName = "";

  // カナ 検索
  filterNameKana = "";

  // 電話番号 検索
  filterTel = "";

  // スキル判定 検索
  skills = [
    {key: "all", value: "全て"},
    {key: "A", value: "A"},
    {key: "B", value: "B"},
    {key: "C", value: "C"},
    {key: "D", value: "D"},
    {key: "null", value: "未登録"}
  ];

  // スキル判定 検索
  skill = this.skills[0]['key'];

  // 代勤経験者
  instead = null;

  // 代勤経験者 選択した項目
  insteadValue = null;

  // 新人
  newcomer = null;

  // 新人 選択した項目
  newcomerValue = null;

  // 退職者表示
  retiree = false;

  // 新人タブツールチップ
  tooltip1 = '';
  tooltip2 = '';
  tooltip3 = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('TABLE') table: ElementRef;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // カラム
  columnDefs = [];

  // 在職者カラム
  column = [
    'select',
    'name',
    'createdDate',
    'canInstead',
    'ranking',
    // 'skillAddress',
    // 'skillLongTime',
    'skillCaretaker',
    // 'skillMail',
    // 'skillMultiple',
    'skillInitial',
    'skillMemo',
    'banned',
    'numberPhone',
    'numberMobilePhone'
  ];

  
  // 退職者カラム
  retireeColumn = [
    'select',
    'name',
    'dateResignation',
    'numberPhone',
    'numberMobilePhone'
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog1: MatDialog,
    private dialog2: MatDialog,
    private dialog3: MatDialog,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    // 前月はじめ
    let month1 = moment().subtract(1, 'M').format('YYYY/MM/01')
    // 前々月はじめ
    let month2 = moment().subtract(2, 'M').format('YYYY/MM/01')
    // 前々々月はじめ
    let month3 = moment().subtract(3, 'M').format('YYYY/MM/01')

    this.tooltip1 = month1 + ' ~ ' + moment(month1).endOf('months').format('YYYY/MM/DD') + ' 入社'
    this.tooltip2 = month2 + ' ~ ' + moment(month2).endOf('months').format('YYYY/MM/DD') + ' 入社'
    this.tooltip3 = month3 + ' ~ ' + moment(month3).endOf('months').format('YYYY/MM/DD') + ' 入社'
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
  * ページ読み込み後の初期化メソッド
  */
  ngAfterViewInit() {
    this.filterName = 
      localStorage.getItem('search_staffs_name') != null ? localStorage.getItem('search_staffs_name') : "";
    this.filterNameKana = 
      localStorage.getItem('search_staffs_nameKana') != null ? localStorage.getItem('search_staffs_nameKana') : "";
    this.filterTel = 
      localStorage.getItem('search_staffs_tel') != null ? localStorage.getItem('search_staffs_tel') : "";
    this.instead = 
      localStorage.getItem('instead') != null ? localStorage.getItem('instead') : null;
    this.newcomer = 
      localStorage.getItem('newcomer') != null ? localStorage.getItem('newcomer') : null;
    this.newcomerValue = 
      localStorage.getItem('newcomerValue') != null ? localStorage.getItem('newcomerValue') : null;
      this.retiree = 
        localStorage.getItem('retiree') != null ? JSON.parse(localStorage.getItem('retiree').toLowerCase()) : false;

    if (!this.retiree) {
      this.getStaffData();
    } else {
      this.getRetiree();
    }
    this.cdr.detectChanges();

    this.dialog1.afterAllClosed.subscribe(res => {
      
      if (!this.retiree) {
        this.getStaffData();
      } else {
        this.getRetiree();
      }
    });
  }

  applyFilter() {
    if (!this.retiree) {
      this.getStaffData();
    } else {
      this.getRetiree();
    }
  }

  /**
  * 清掃員一覧データ取得
  */
  getStaffData() {
    localStorage.setItem('search_staffs_name', this.filterName);
    localStorage.setItem('search_staffs_nameKana', this.filterNameKana);
    localStorage.setItem('search_staffs_tel', this.filterTel);
    localStorage.setItem('instead', this.instead);
    localStorage.setItem('newcomer', this.newcomer);
    localStorage.setItem('newcomerValue', this.newcomerValue);
    localStorage.setItem('retiree', String(this.retiree));
    this.nowLoading = true;

    this.columnDefs = this.column;

    this.http.get(

      API_BASE_URL + '/api/v1/instructEmployment/allStaff?s=0&e=8000'
        + "&filterName=" + encodeURIComponent(this.filterName)
        + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
        + "&filterTel=" + encodeURIComponent(this.filterTel)
        + "&instead=" + this.instead
        + "&newcomer=" + this.newcomer
        + "&skill=" + this.skill,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as []);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;
      this.adjustmentSelection();
      this.setExport(res as []);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
  
  /**
   * エクスポート用にデータを生成
   * @param allData 対象データ
   */
  setExport(allData) {
    if (allData && allData.length) {
      this.exportData = [];
      // エクセル用にカラムを生成
      for (let i = 0; i < allData.length; i++) {
        const a = allData[i];

        let target = {};
        let date = '';
        // 在職者
        if (!this.retiree) {
          if (a['createdDate'] && a['createdDate'] != '') {
            date = moment(a['createdDate']).format('YYYY/MM/DD');
          }
          target = {
            '清掃員名': a['name']
          , 'スキル更新日': date
          , '代勤可能': a['canInstead']
          , '清掃スキル': a['ranking']
          , '管理業務': a['skillCaretaker']
          , '初期対応': a['skillInitial']
          , '備考': a['skillMemo']
          , 'TEL': a['numberPhone']
          , '携帯TEL': a['numberMobilePhone']
          , '住所': a['address']
          }
        // 退職者
        } else {
          if (a['dateResignation'] && a['dateResignation'] != '') {
            date = moment(a['dateResignation']).format('YYYY/MM/DD');
          }
          target = {
            '清掃員名': a['name']
          , '退職日': date
          , 'TEL': a['numberPhone']
          , '携帯TEL': a['numberMobilePhone']
          , '住所': a['address']
          }
        }
        this.exportData.push(target);
      }
    } else {
      this.exportData = [];
    }
    this.loadingExport = false;
  }
  
  /**
   * トグルイベント 代勤
  */
  changeInsteadToggle(value) {
    // 2度クリックされたらトグル外す
    if (this.insteadValue == value) {
      this.instead = null;
      this.insteadValue = null;
    } else {
      this.insteadValue = value;
    }
    this.paginator.pageIndex = 0;
    this.getStaffData();
  }

  /**
   * トグルイベント 新人
  */
  changeNewcomerToggle(value) {
    // 2度クリックされたらトグル外す
    if (this.newcomerValue == value) {
      this.newcomer = null;
      this.newcomerValue = null;
    } else {
      this.newcomerValue = value;
    }
    this.paginator.pageIndex = 0;
    this.getStaffData();
  }

  /**
   * エクスポート
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '清掃員一覧';
    if (this.retiree) {
      fileName = '退職者一覧';
    } else {
      switch (this.instead) {
        case 'oneMonth':
          fileName = '代勤者_過去1ヶ月'
          break;
  
        case 'threeMonths':
          fileName = '代勤者_過去3ヶ月'
          break;
        
        case 'halfYear':
          fileName = '代勤者_過去半年'
          break;
      
        case 'oneYear':
          fileName = '代勤者_過去1年'
          break;
      
        case 'none':
          fileName = '代勤経験なし'
          break;
      
        default:
          break;
      }
      // 新人
      let name = ''
      switch (this.newcomer) {
        case 'oneMonth':
          name = '（新人_1ヶ月以内）'
          break;
  
        case 'threeMonths':
          name = '（新人_3ヶ月以内）'
          break;
      
        default:
          break;
      }
    }
    
    XLSX.writeFile(wb, fileName + name + '.xlsx');
  }

  /**
   * スキル項目ボタン イベント
   */
  setSkillItem() {
    this.dialog1.open(SkillItemDialogComponent, {
    });
  }

  /**
   * 退職者データ取得
   */
  getRetiree() {
    localStorage.setItem('retiree', String(this.retiree));
    if (!this.retiree) {
      return;
    }
    this.columnDefs = this.retireeColumn;
    localStorage.setItem('search_staffs_name', this.filterName);
    localStorage.setItem('search_staffs_nameKana', this.filterNameKana);
    localStorage.setItem('search_staffs_tel', this.filterTel);
    this.nowLoading = true;

    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/allRetiree?s=0&e=8000'
        + "&filterName=" + encodeURIComponent(this.filterName)
        + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
        + "&filterTel=" + encodeURIComponent(this.filterTel),
      {headers: this.headers}
    )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as []);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;
      this.adjustmentSelection();
      this.setExport(res as []);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 清掃員名クリックイベント
   */
  staffDetailDialogOpen(event: any) {
    this.dialog2.open(StaffDetailDialogComponent, {
      data: {
        data: event
      }
    });
  }

  /**
   * MAPクリックイベント
   */
  clickMap(event: any) {
    this.dialog2.open(MapDialogComponent, {
      maxWidth: '95vw',
      data: {
        staffs: this.exportData
      }
    });
  }

  /**
   * Selection 調整
   * 再抽出でdataSourceがさし変わった際、新たなデータでSelectionの中身を入れ替えする。
   */
  adjustmentSelection() {
    this.selection.selected.forEach(select => {
      if (select) {
        const newSelect = this.dataSource.data.find(row =>
          row['staffDailyCleaning'] && select.staffDailyCleaning && row['staffDailyCleaning'] == select.staffDailyCleaning);
        if (newSelect) {
          // 選択解除
          this.selection.toggle(select);
          // 新たなデータで選択
          this.selection.toggle(newSelect);
        }
      }
    });
  }

  /**
   * 全選択済み
   * @returns 
   */
  isAllSelected() {
    if (!this.dataSource || !this.dataSource.data) return false;
    for (const row of this.dataSource.data) {
      if (!this.selection.isSelected(row)) return false
    }
    return true;
  }

  /**
   * 全選択 クリック
   * @returns 
   */
  masterToggle() {
    this.isAllSelected() ?
        this.clearSelection() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /**
   * 選択行クリア
   * 表示している行の選択をクリアする。
   * 検索条件で表示対象外となっているデータの選択はクリアしない。
   */
  clearSelection() {
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row)) {
        this.selection.toggle(row);
      }
    })
  }

  /**
   * 宛名印刷 ボタン
   */
  addressPrint() {
    const selectionCds = [];
    for (const select of this.selection.selected) {
      if (selectionCds.indexOf(select.staffDailyCleaning) == -1) selectionCds.push(select.staffDailyCleaning);
    }

    if (selectionCds.length > 100) {
      window.alert('宛名印刷は最大100件になります。現在' + selectionCds.length + '件指定しています。\r\n100件以下になるよう選択しなおしてください。');
      return;
    }

    // サイズ指定ダイアログ
    this.dialog3.open(AddressPrintDialogComponent, { data: { selection: selectionCds, url: '/api/v1/instructEmployment/address-pdf/' }});
  }
}
