import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  selection: any;
  url: String;
}

@Component({
  selector: 'app-address-print-dialog',
  templateUrl: './address-print-dialog.component.html',
  styleUrls: ['./address-print-dialog.component.less'],
})

export class AddressPrintDialogComponent implements OnInit {

  nowLoading = false;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  public chk = new Array(30).fill(true);

  // httpヘッダー
  public httpOptions;

  constructor(private http: HttpClient, private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private dialogRef: MatDialogRef<AddressPrintDialogComponent>,
    private ngZone: NgZone) { }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 印刷 クリック
   */
  clickPrint(size) {
    this.nowLoading = true;

    this.http.post(API_BASE_URL + this.data.url + size,
      this.data.selection, {headers: this.headers, responseType: 'text'})
      .subscribe(res=> {
        this.downloadPdf(res);
        this.nowLoading = false;
        this.dialogRef.close();
      }, error => {
        this.nowLoading = false;
        alert("セッションが切れています");
        this.dialogRef.close();
        this.router.navigate(['/']);
      })
  }

  /**
   * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
   * 
   * @param base64
   */
  downloadPdf (base64) {
    var mime_ctype = "application/pdf";
    var blob = this.toBlob(base64, mime_ctype);
    window.open(window.URL.createObjectURL(blob), '_blank')      
  }
   
  /**
   * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
   * 日本語対応。
   * 
   * @param base64 
   * @param mime_ctype MIMEコンテンツタイプ
   * @returns Blob
   */
  toBlob(base64, mime_ctype) {
    // 日本語の文字化けに対処するためBOMを作成する。
    var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    try {
        var blob = new Blob([bom, buffer.buffer], {
            type: mime_ctype,
        });
    } catch (e) {
        return null;
    }
    return blob;
  }  
}


