import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatDatepicker, MatDatepickerInputEvent, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export interface DialogData {
  data
}

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY年',
  },
  display: {
    dateInput: 'YYYY年',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-staff-detail-dialog',
  templateUrl: './staff-detail-dialog.component.html',
  styleUrls: ['./staff-detail-dialog.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS},
  ],
})

export class StaffDetailDialogComponent implements OnInit {

  instructEmployment = [];

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 有休履歴表の「表示年」年月日
  selectedYearHistory = new FormControl(moment());

  // 実績時間表の「表示年」年月日
  selectedYearTime = new FormControl(moment());

  // 有休履歴表の「表示年」年のみ
  paidHistoryYear: number = moment(this.selectedYearHistory.value).year();

  // 実績時間表の「表示年」年のみ
  actualTimeYear: number = moment(this.selectedYearTime.value).year();

  // 清掃員コード
  staffDailyCleaning: string;

  // 有休残数
  paidHolidayLeft: string;

  // 有休履歴
  paidHolidayList: any;

  // 作業実績
  monthWorkRecord: any;

  // 選択した物件名
  selectedSite: any;

  // 選択した物件情報
  siteData = [];

  // 契約中の物件がない
  noContractSite = false;
  
  // 有休履歴リストのデータ数がない
  noPaidHolidayList = false;

  // ローディング中
  nowLoading = false;

  // 代勤スキル
  skill = {};

  // 代勤スキル評定項目
  skillScoreItem = new MatTableDataSource();

  // 代勤スキル評定カラム名
  columnDefs = [
    'item',
    'score'
  ]

  // ランク
  rank = {
    rank: null,
    rankId: {}
  };

  // 登録ボタン非活性⇒true
  registerRankDisable = false;
  registerDisable1 = false;
  registerDisable2 = false;

  // 出禁リスト
  banned = [];

  // httpヘッダー
  public httpOptions;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) { }

  ngOnInit() {
    // console.log(this.data)
    let staff = this.data.data.staffDailyCleaning;
    let cdSite = this.data.data.cdSite || null;
    let sequenceNumber = this.data.data.sequenceNumber || null;

    this.staffDailyCleaning = staff;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.getSiteData(staff, () => {
      // 契約中の物件があれば情報を取得
      if (!this.noContractSite) {

        // 稼働管理以外から遷移した場合
        if (!cdSite) {
          sequenceNumber = this.siteData[0].sequenceNumber;
          cdSite = this.siteData[0].cdSite;
          // ランク取得
          this.getRank(cdSite, this.siteData[0].cdContract);
        } else {
          // 稼働管理から遷移した際は、物件ドロップダウンを同じ物件に合わせる
          for (let i = 0; i < this.siteData.length; i++) {
            if (this.siteData[i].sequenceNumber == sequenceNumber) {
              this.selectedSite = this.siteData[i]
              break;
            }
          }
          // ランク取得
          this.getRank(cdSite, this.selectedSite.cdContract);
        }

        this.getStaffDetailData(staff, sequenceNumber, cdSite);
      // なければ、清掃員のみの情報を取得
      } else {
        this.getStaffDetailDataNoContract(staff);
      }

      this.getPaidHolidaysLeft(staff);
      this.getPaidHolidays(staff);
      this.getMonthWorkRecord(staff);
      this.getSkill(staff);
    })
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 一覧情報取得（契約中の物件がある清掃員）
   * @param staff 清掃員コード 
   * @param sequence シーケンス
   * @param site 物件コード
   * @param cb コールバック
   */
  getStaffDetailData(staff: string, sequence: string, site: string) {
    this.nowLoading = true;
    this.http.get(API_BASE_URL + '/api/v1/instructEmployment/staffDetail?staffDailyCleaning='
      + staff
      + "&sequenceNumber=" + sequence
      + "&cdSite=" + site,
      {headers: this.headers}).subscribe((res) => {
        console.info("res = " + res);
        this.instructEmployment = res as [];
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * ランク取得（契約中の物件がある清掃員）
   * @param site 物件コード
   * @param cdContract 契約コード
   * @param cb コールバック
   */
  getRank(site: string, cdContract: string) {
    this.rank.rank = null
    let result = {};
    this.http.get(API_BASE_URL + '/api/v1/rank?cdSite='
      + site
      + "&cdContract=" + cdContract,
      {headers: this.headers}).subscribe((res) => {
        result = res;
        // 新規登録
        if (!result) { 
          this.rank.rankId['cdSite'] = site;
          this.rank.rankId['cdContract'] = cdContract;
        // 編集
        } else {
          this.rank.rank = result['rank'];
          this.rank.rankId = result['rankId'];
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * 一覧情報取得（契約中の物件がない清掃員）
   * @param staff 清掃員コード 
   * @param cb コールバック
   */
    getStaffDetailDataNoContract(staff: string) {
      this.nowLoading = true;
      this.http.get(API_BASE_URL + '/api/v1/instructEmployment/vMstStaff?staffDailyCleaning=' + staff,
        {headers: this.headers}).subscribe((res) => {
          if (res != null) {
            this.instructEmployment = res as [];
          }
          this.nowLoading = false;
        },
        error => {
          alert("認証に失敗しました");
          this.router.navigate(['/']);
      });
    }

  /**
   * 有休残数を取得
   * @param staffDailyCleaning 清掃員コード
   */
  getPaidHolidaysLeft(staffDailyCleaning: string) {
    this.http.get(API_BASE_URL + '/api/v1/paidHolidays/left?staffDailyCleaning='
      + staffDailyCleaning,
      {headers: this.headers})
      .subscribe((PaidHolidays) => {
        this.paidHolidayLeft = PaidHolidays['intRes2'] + " 日";
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * 有休履歴を取得
   * @param staffDailyCleaning 清掃員コード
   */
  getPaidHolidays(staffDailyCleaning: string) {
    this.noPaidHolidayList = false;
    this.http.get(API_BASE_URL + '/api/v1/paidHolidays?staffDailyCleaning='
      + staffDailyCleaning
      + '&year=' + this.paidHistoryYear,
      {headers: this.headers})
      .subscribe((res) => {
        this.paidHolidayList = res;
        if (this.paidHolidayList.length == 0) {
          this.noPaidHolidayList = true;
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * 作業実績(月)を取得
   * @param staffDailyCleaning 清掃員コード
   */
  getMonthWorkRecord(staffDailyCleaning: string) {
    this.http.get(API_BASE_URL + '/api/v1/instructEmployment/monthWorkRecord?staffDailyCleaning='
        + staffDailyCleaning
        + '&year=' + this.actualTimeYear,
      {headers: this.headers})
      .subscribe((ret:any[]) => {
        let newRecord:any[] = [];
        ret.forEach( rec => {
          let future = false;
          if (new Date(rec.workMonth) > new Date()) future = true;

          newRecord.push({
            workMonth: rec.workMonth
          , workDays: future ? '-' : rec.workDays + ' 日'
          , workTimes: future ?  '-' : rec.workTimes
          , paidHolidayUseCount: future ?  '-' : rec.paidHolidayUseCount + ' 日'
          });
        });
        this.monthWorkRecord = newRecord;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * 有休履歴表の「表示年」切替
   * @param normalizedYear 選択した年
   */
  // 手入力した場合
  historyHandlerManual(normalizedYear: MatDatepickerInputEvent<moment.Moment>) {
    const ctrlValue = this.selectedYearHistory.value;
    ctrlValue.year(normalizedYear);
    this.selectedYearHistory.setValue(ctrlValue);
    this.paidHistoryYear = moment(this.selectedYearHistory.value).year();
    this.getPaidHolidays(this.staffDailyCleaning);
  }
  // datepickerで選択した場合
  historyHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.selectedYearHistory.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedYearHistory.setValue(ctrlValue);
    this.paidHistoryYear = moment(this.selectedYearHistory.value).year();
    datepicker.close();
    this.getPaidHolidays(this.staffDailyCleaning);
  }

  /**
   * 実績時間表の「表示年」切替
   * @param normalizedYear 選択した年
   */
  // 手入力した場合
  timeHandlerManual(normalizedYear: MatDatepickerInputEvent<moment.Moment>) {
    const ctrlValue = this.selectedYearTime.value;
    ctrlValue.year(normalizedYear);
    this.selectedYearTime.setValue(ctrlValue);
    this.actualTimeYear = moment(this.selectedYearTime.value).year();
    this.getMonthWorkRecord(this.staffDailyCleaning);
  }
  
  timeHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.selectedYearTime.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedYearTime.setValue(ctrlValue);
    this.actualTimeYear = moment(this.selectedYearTime.value).year();
    datepicker.close();
    this.getMonthWorkRecord(this.staffDailyCleaning);
  }

  /**
   * 物件ドロップダウンリスト作成、物件情報取得
   * @param staff 清掃員コード
   */
  getSiteData(staff: string, cb) {
    this.noContractSite = false;

    this.http.get(API_BASE_URL + '/api/v1/instructEmployment/staffDetailSite?staffDailyCleaning=' + staff,
    {headers: this.headers})
    .subscribe((res) => {

      let data = res as any[];

      if (data.length == 0) {
        this.noContractSite = true;
      } else {
        for (let i = 0; i < data.length; i++) {
          this.siteData.push({
            staffDailyCleaning: staff,
            cdSite: data[i].cdSite,
            sequenceNumber: data[i].sequenceNumber,
            nameSite: data[i].nameSite,
            cdContract: data[i].cdContract
          });
        }
        this.selectedSite = this.siteData[0];
      }

      if(cb) {
        cb(res);
      }
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 物件選択イベント
   */
  onChangeSite() {
    this.getStaffDetailData(this.selectedSite.staffDailyCleaning, this.selectedSite.sequenceNumber, this.selectedSite.cdSite);
    this.getRank(this.selectedSite.cdSite, this.selectedSite.cdContract);
  }

  /**
   * 代勤スキル、出禁データ取得
   * @param staff 清掃員コード
   */
  getSkill(staff: string) {

    // 代勤スキル　チェックボックス、　出禁リスト　取得
    this.http.get(API_BASE_URL + '/api/v1/skill?staffDailyCleaning=' + staff,
    {headers: this.headers})
      .subscribe((res) => {

        this.skill = res;

        // スキル情報がない場合
        if (!this.skill['staffDailyCleaning']) {
          this.skill = {
            staffDailyCleaning: staff,
            address: false,
            longTime: false,
            caretaker: false,
            mail: false,
            multiple: false,
            initial: false,
            canInstead: null,
            memo: '',
          }
        }

        // 出禁情報
        this.banned = res['banned'];
        delete this.skill['banned'];
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });

    // 代勤スキル　ラジオボタン　取得
    this.http.get(API_BASE_URL + '/api/v1/skillScore?staffDailyCleaning=' + staff,
    {headers: this.headers})
      .subscribe((res) => {
        this.skillScoreItem = new MatTableDataSource(res as []);
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
    });
  }

  /**
   * スキル登録ボタンイベント
   */
  register() {
    this.registerDisable1 = true;
    this.registerDisable2 = true;
    this.skill['createdUser'] = localStorage.getItem("uid");

    // 代勤スキル　チェックボックス
    this.http.post(
      API_BASE_URL + "/api/v1/skill/save?name=" + this.instructEmployment['nameStaff'], this.skill, this.httpOptions
    ).subscribe(res => {
      alert("登録しました。")
      this.ngOnInit()
      this.registerDisable1 = false;
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.registerDisable1 = false;
    });

    // 代勤スキル　ラジオボタン
    let data = this.skillScoreItem.data;

    for (let i = 0; i < data.length; i++) {
      data[i]['createdUser'] = localStorage.getItem("uid");
      if (!data[i]['id']) {
        delete data[i]['id'];
      }
    }

    this.http.post(
      API_BASE_URL + "/api/v1/skillScore/save", data, this.httpOptions
    ).subscribe(res => {
      this.ngOnInit()
      this.registerDisable2 = false;
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.registerDisable2 = false;
    });
  }

  /**
   * ランク登録
   */
  registerRank() {
    this.registerRankDisable = true;
    this.rank['createdUser'] = localStorage.getItem("uid");

    this.http.post(
      API_BASE_URL + "/api/v1/rank/save?name=" + this.instructEmployment['nameStaff']
        + '&nameSite=' +  this.selectedSite.nameSite
        , this.rank
        , this.httpOptions
    ).subscribe(res => {
      alert("登録しました。")
      this.ngOnInit()
      this.registerRankDisable = false;
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.registerRankDisable = false;
    });
  }
}
