import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';

export interface DialogData {
  any
}

export const DATE_FORMATS = {
  parse: {
    dateInput: "YYYY年MM月DD日",
  },
  display: {
    dateInput: "YYYY年MM月DD日",
    monthYearLabel: "YYYY MMM DD",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM DD",
  },
}

@Component({
  selector: 'app-export-history-dialog',
  templateUrl: './export-history-dialog.component.html',
  styleUrls: ['./export-history-dialog.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})

export class ExportHistoryDialogComponent implements OnInit {
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  blob: Blob;

  //「開始年月」初期値_現在の日付から1ヵ月前の日付を取得
  start = moment().subtract(1, 'months');

  //「終了年月」初期値_現在の日付から1ヵ月前の日付を取得
  end = moment().subtract(1, 'months');

  // ローディング
  loadingCompleteData:boolean = true;

  constructor(private http: HttpClient, private router: Router, private dialogRef: MatDialogRef<ExportHistoryDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * カレンダーの年選択時
   * @param normalizedYear 選択した年
   */
  chosenYearHandler1(normalizedYear: moment.Moment) {
    this.start = normalizedYear;
  }

  /**
   * カレンダーの月選択時
   * @param normalizedMonth 選択した月
   * @param datepicker
   * @param screenDisplay 画面表示切替
   */
  chosenMonthHandler1(normalizedMonth: Moment) {
    this.start = normalizedMonth;
  }
  
  /**
   * カレンダーの年選択時
   * @param normalizedYear 選択した年
   */
  chosenYearHandler2(normalizedYear: moment.Moment) {
    this.end = normalizedYear;
  }

  /**
   * カレンダーの月選択時
   * @param normalizedMonth 選択した月
   * @param datepicker
   * @param screenDisplay 画面表示切替
   */
  chosenMonthHandler2(normalizedMonth: Moment) {
    this.end = normalizedMonth;
  }

  /**
   * 出力
   */
  downloadCSV() {
    if (!this.start || !this.end) {
      alert('出力する範囲を選択してください。')
      return
    }

    let start = moment(this.start).startOf('month').format('YYYY-MM-DD')
    let end = moment(this.end).endOf('month').format('YYYY-MM-DD')

    if (!moment(end + ' 00:00:00').isAfter(moment(start + ' 00:00:00'))) {
      alert('出力する範囲の開始日と終了日が逆です。範囲を変更してください。')
      return
    }

    this.loadingCompleteData = false;

    let title = '有給使用履歴' + moment(this.start).format('YYYY年MM月');
    title += '~' + moment(this.end).format('YYYY年MM月');

    this.http.get(
      API_BASE_URL + '/api/v1/paidHolidays/downloadHistory?start=' + start
      + '&end=' + end,
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let report = res as any;

      this.blob = new Blob([report], {type: 'text/xlsx'});
      var downloadURL = window.URL.createObjectURL(report);
      var link = document.createElement('a');
      link.href = downloadURL;

      // ダウンロードしたファイル名
      link.download = title + '.xlsx';

      link.click();
      this.loadingCompleteData = true;
    },
    error => {
      this.loadingCompleteData = true;
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}


