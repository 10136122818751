import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { DateAdapter, MatDatepicker, MatDatepickerInputEvent, MatPaginator, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { BaseComponent } from '../base.component';
import { StaffDetailDialogComponent } from '../staffs/staff-detail-dialog/staff-detail-dialog.component';
import { ExportHistoryDialogComponent } from './export-history-dialog/export-history-dialog.component';

// 「年月指定」フォーマット
export const YEAR_MONTH_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月',
  },
  display: {
    dateInput: 'YYYY年MM月',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-paid-holiday',
  templateUrl: './paid-holiday.component.html',
  styleUrls: ['./paid-holiday.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMATS},
  ]
})

export class PaidHolidayComponent extends BaseComponent implements OnInit {
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });
  
  // テーブルのカラム名
  columnDefs = [
    'name',
    'allRemainingCount',
    'remainingUseObligation',
    'expirationDate',
    'toStaffDetail'
  ];

  // ページネーター
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // エクスポート時の内容
  // @ViewChild('TABLE') table: ElementRef;

  // テーブルのデータソース
  paidHolidayList = new MatTableDataSource();

  // エクスポート用データ
  exportData = [];

  // 全レコードの件数
  dataSourceLength = 0;

  // ローディング中の表示
  nowLoading = true;

  // エクスポート用ローディング
  loadingExport = true;

  // 指定年月
  selectedMonthYear = new FormControl(moment());

  // データ件数
  paginatorLength = 0;
  
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private dialog2: MatDialog,
    private ngZone: NgZone
    ) {
    super();
  }

  ngOnInit() {
    this.getPaidHolidayList(this.paginator.pageIndex, 50);
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    this.paginator.page.subscribe(() => {
      this.getPaidHolidayList(this.paginator.pageIndex, this.paginator.pageSize);
    })
  }

  /**
   * 「指定年月」手入力した場合
   * @param normalized 入力した年月
   */
  handlerManual(normalized: MatDatepickerInputEvent<moment.Moment>) {
    this.paginator.pageIndex = 0;
    this.selectedMonthYear.setValue(normalized);
    this.getPaidHolidayList(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 「指定年月」datepickerで年を選択した場合
   * @param normalized 選択した年月
   */
  chosenYearHandler(normalized: moment.Moment) {
    this.selectedMonthYear.setValue(normalized);
  }

  /**
   * 「指定年月」datepickerで月を選択した場合
   * @param normalized 選択した年月
   * @param datepicker datepickerカレンダー
   */
  chosenMonthHandler(normalized: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    this.paginator.pageIndex = 0;
    this.selectedMonthYear.setValue(normalized);
    datepicker.close();
    this.getPaidHolidayList(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * リストのデータ取得
   */
  getPaidHolidayList(index: number, size: number) {
    this.nowLoading = true;
    this.paginatorLength = 0;
    let date = moment(this.selectedMonthYear.value).format('YYYY-MM-DD');
    this.http.get(
      API_BASE_URL + '/api/v1/paidHolidays/usingObligation?s=0&e=8000&index=' + index + '&size=' + size + '&date=' + date,
      {headers: this.headers}
    )
    .subscribe(res => {
      let data = res as any[];
      if (data.length == 0) {
        data.push({
          name :  '-',
          allRemainingCount : '-',
          remainingUseObligation : '-',
          expirationDate : '-'
        })
      } else {
        this.paginatorLength = data[0].totalSize;
      }
      this.paidHolidayList = new MatTableDataSource(data);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
    this.getAll();
  }

  /**
   * エクスポート用の全件データ取得
   */
  getAll() {
    this.loadingExport = true;
    let date = moment(this.selectedMonthYear.value).format('YYYY-MM-DD');
    this.http.get(
      API_BASE_URL + '/api/v1/paidHolidays/allUsingObligation?s=0&e=8000&date=' + date,
      {headers: this.headers}
    )
    .subscribe(res => {
      let allData = res as [];
      if (allData && allData.length) {
        this.exportData = [];
        // エクセル用にカラムを生成
        for (let i = 0; i < allData.length; i++) {
          const a = allData[i];
  
          this.exportData.push({
              '清掃員名': a['name']
            , '有休残数': a['allRemainingCount']
            , '使用義務残数': a['remainingUseObligation']
            , '使用期限日': a['expirationDate']
          });
        }
      } else {
        this.exportData = [];
      }
      this.loadingExport = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 残数出力
   */
  leftXlsx() {
    this.loadingExport = true;
    let title = '有給残数' + moment().format('_YYYY.MM.DD');

    this.http.get(
      API_BASE_URL + '/api/v1/paidHolidays/downloadLeft',
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let data = res as any;
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;

      // ダウンロードしたファイル名
      link.download = title + '.xlsx';

      link.click();
    },
    error => {
      alert("認証に失敗しました\r\n" + error);
      this.router.navigate(['/']);
      this.loadingExport = false;
    });
    this.loadingExport = false;
  }

  /**
   * 使用履歴出力
   */
  historyXlsx() {
    this.dialog2.open(ExportHistoryDialogComponent, {});
  }

  /**
   * エクスポート
   */
  exportXlsx() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, '有休5日使用義務.xlsx');
  }

  /**
   * 名前クリックイベント　稼働管理へ遷移
   * @param name 清掃員名
   */
  toOperationPage(name: string) {
    localStorage.setItem('search_staffs_name', name);
  }

  /**
   * 詳細クリックイベント
   */
  staffDetailDialogOpen(event: any) {
    this.dialog.open(StaffDetailDialogComponent, {
      data: {
        data: event
      }
    });
  }
}
