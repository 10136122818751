import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Router }     from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { API_BASE_URL }                   from '../../environments/environment';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base.component';
import { AddressPrintDialogComponent } from '../common/address-print-dialog/address-print-dialog.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface Customer {
  nameBillingAddress1: string;
  nameBililngAddress2: string;
  address1: string;
  numberPhone: string;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.less']
})
export class CustomersComponent extends BaseComponent implements OnInit {
// 得意先一覧テーブルのデータソース
public dataSource = new MatTableDataSource();

selection = new SelectionModel(true, []);

// エクスポート用全件データ
exportData = [];

public nowLoading = true;

public filterNameBillingAddress = "";

public filterNameBillingAddressKana = "";

@ViewChild(MatPaginator) paginator: MatPaginator;

@ViewChild('TABLE') table: ElementRef;

public headers = new HttpHeaders({
  "X-LoginAccessKey": localStorage.getItem("accessKey")
});

public columnDefs = [
  'select',
  'nameBillingAddress1',
  'nameBillingAddress2',
  'address1',
  'numberPhone'
];

constructor(private http: HttpClient, private router: Router, private ngZone: NgZone, private dialog: MatDialog) {
  super();
}

  ngOnInit() {}

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
  * ページ読み込み後の初期化メソッド
  */
  ngAfterViewInit() {
    console.info("ngAfterViewInit()");
    this.getAgreements();
  }
  /**
  * セルクリック時のコールバック
  * @param event 選択された行オブジェクト
  */
  cellClicked(event) {
    this.router.navigate(['/customers/' + event.cdBillingAddress]);
  }

  applyFilter() {
    this.getAgreements();
  }

  /**
  * 契約一覧を取得します
  */
  getAgreements() {
    this.nowLoading = true;
    console.info(this.filterNameBillingAddress + ", " + this.filterNameBillingAddressKana);
    this.http.get(
      API_BASE_URL + '/api/v1/billingAddresses/all?filterNameBillingAddress=' + encodeURIComponent(this.filterNameBillingAddress)
              + "&filterNameBillingAddressKana=" + encodeURIComponent(this.filterNameBillingAddressKana),
      {headers: this.headers}
    )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as Customer[]);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;

      this.adjustmentSelection();
      this.exportData = res as [];
      // エクスポート用に生成
      let data = []
      for (let i = 0; i < this.exportData.length; i++) {
        const e = this.exportData[i]
        data.push({ '得意先名':  e.nameBillingAddress1, '支店名': e.nameBillingAddress2, '住所': e.address1, 'TEL': e.numberPhone})
      }
      this.exportData = data;

      this.nowLoading = false;
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 全選択済み
   * @returns 
   */
  isAllSelected() {
    for (const row of this.dataSource.data) {
      if (!this.selection.isSelected(row)) return false
    }
    return true;
  }

  /**
   * 全選択 クリック
   * @returns 
   */
  masterToggle() {
    this.isAllSelected() ?
        this.clearSelection() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  
  /**
   * エクスポートボタン
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '得意先一覧.xlsx';
    XLSX.writeFile(wb, fileName);
  }

  /**
   * Selection 調整
   * 再抽出でdataSourceがさし変わった際、新たなデータでSelectionの中身を入れ替えする。
   */
  adjustmentSelection() {
    this.selection.selected.forEach(select => {
      if (select) {
        const newSelect = this.dataSource.data.find(row => 
          row['cdBillingAddress'] && select.cdBillingAddress && row['cdBillingAddress'] == select.cdBillingAddress);
        if (newSelect) {
          // 選択解除
          this.selection.toggle(select);
          // 新たなデータで選択
          this.selection.toggle(newSelect);
        }
      }
    });
  }

  /**
   * 選択行クリア
   * 表示している行の選択をクリアする。
   * 検索条件で表示対象外となっているデータの選択はクリアしない。
   */
  clearSelection() {
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row)) {
        this.selection.toggle(row);
      }
    })
  }

  /**
   * 宛名印刷 ボタン
   */
  addressPrint() {
    const selectionCds = [];
    for (const select of this.selection.selected) {
      if (selectionCds.indexOf(select.cdBillingAddress) == -1) selectionCds.push(select.cdBillingAddress);
    }

    if (selectionCds.length > 100) {
      window.alert('宛名印刷は最大100件になります。現在' + selectionCds.length + '件指定しています。\r\n100件以下になるよう選択しなおしてください。');
      return;
    }

    // サイズ指定ダイアログ
    this.dialog.open(AddressPrintDialogComponent, { data: { selection: selectionCds, url: '/api/v1/billingAddresses/address-pdf/' }});
  }
}
