import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import { MatTableModule }     from '@angular/material/table';

import { AgGridModule }     from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImporterComponent } from './importer/importer.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { CustomersComponent } from './customers/customers.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { ContractLedgerComponent } from './contract-ledger/contract-ledger.component';
import { StaffsComponent } from './staffs/staffs.component';
import { SkillItemDialogComponent } from './staffs/skill-item-dialog/skill-item-dialog.component';
import { StaffDetailDialogComponent } from './staffs/staff-detail-dialog/staff-detail-dialog.component';
import { MapDialogComponent } from './utility/map-dialog/map-dialog.component';

import { BuildingSettingComponent } from './building-setting/building-setting.component';
import { BuildingSettingDetailComponent } from './building-setting-detail/building-setting-detail.component';
import { BuildingDetailComponent } from './building-detail/building-detail.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { AgreementDetailComponent } from './agreement-detail/agreement-detail.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { DatePipe } from '@angular/common';
import {
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatDialogModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatInputModule,
  MatTooltipModule,
  MatToolbarModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatExpansionModule,
  MatBadgeModule,
  MatBadge,
  MatRadioModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatNativeDateModule,
  MatSelectModule,
  MAT_DATE_LOCALE,
  MatSlideToggleModule
} from '@angular/material';
import { UserMasterComponent } from './user-master/user-master.component';
import { OperationComponent } from './operation/operation.component';
import { OperationDialogComponent } from './operation/operation-dialog/operation-dialog.component';
import { SmsDialogComponent } from './operation/sms-dialog/sms.component';
import { SpotDialogComponent } from './operation/spot-dialog/spot-dialog.component';
import { HolidayDialogComponent } from './operation/holiday-dialog/holiday-dialog.component';
import { ExchangeDialogComponent } from './operation/exchange-dialog/exchange-dialog.component';
import { ExchangeEditDialogComponent } from './operation/exchange-edit-dialog/exchange-edit-dialog.component';
import { ContractDetailNoteDialogComponent } from './contract-detail/contract-detail-note-dialog/contract-detail-note-dialog.component';
import { AreaMasterComponent } from './area-master/area-master.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog/confirmation-dialog.component';
import { ExportCsvDialogComponent } from './confirmation/export-csv-dialog/export-csv-dialog.component';
import { NationalHolidayComponent } from './national-holiday/national-holiday.component';
import { NationalHolidayDialogComponent } from './national-holiday/national-holiday-dialog/national-holiday-dialog.component';
import { BuildingsExportDialogComponent } from './buildings/buildings-export-dialog/buildings-export-dialog.component';
import { PaidHolidayComponent } from './paid-holiday/paid-holiday.component';
import { ExportHistoryDialogComponent } from './paid-holiday/export-history-dialog/export-history-dialog.component';
import { ImportReportDialogComponent } from './confirmation/import-report-dialog/import-report-dialog.component';
import { RecruitComponent } from './recruit/recruit.component';
import { RecruitDialogComponent } from './recruit/recruit-dialog/recruit-dialog.component';
import { VacancyComponent } from './vacancy/vacancy.component';
import { LogComponent } from './log/log.component';
import { YearMonthDatepickerComponent } from './year-month-datepicker/year-month-datepicker.component';
import { ProfitDialogComponent } from './buildings/profit-dialog/profit-dialog.component';
import { ProfitChartComponent } from './buildings/profit-chart/profit-chart.component';
import { ChartsModule } from 'ng2-charts';
import { ManagementPlanDialogComponent } from './agreements/management-plan-dialog/management-plan.component';
import { ClientSelectionDialogComponent } from './agreements/client-selection-dialog/client-selection.component';
import { AddressPrintDialogComponent } from './common/address-print-dialog/address-print-dialog.component';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);    
    }
}
@NgModule({
  declarations: [
    AppComponent,
    ImporterComponent,
    BuildingsComponent,
    CustomersComponent,
    AgreementsComponent,
    ContractDetailComponent,
    ContractLedgerComponent,
    StaffsComponent,
    SkillItemDialogComponent,
    StaffDetailDialogComponent,
    MapDialogComponent,
    BuildingSettingComponent,
    BuildingDetailComponent,
    BuildingSettingDetailComponent,
    CustomerDetailComponent,
    AgreementDetailComponent,
    LoginComponent,
    UserMasterComponent,
    OperationComponent,
    OperationDialogComponent,
    SmsDialogComponent,
    SpotDialogComponent,
    HolidayDialogComponent,
    ExchangeDialogComponent,
    ExchangeEditDialogComponent,
    ContractDetailNoteDialogComponent,
    AreaMasterComponent,
    ConfirmationComponent,
    ConfirmationDialogComponent,
    ExportCsvDialogComponent,
    NationalHolidayComponent,
    NationalHolidayDialogComponent,
    BuildingsExportDialogComponent,
    PaidHolidayComponent,
    ExportHistoryDialogComponent,
    ImportReportDialogComponent,
    RecruitComponent,
    RecruitDialogComponent,
    VacancyComponent,
    LogComponent,
    YearMonthDatepickerComponent,
    ProfitDialogComponent,
    ProfitChartComponent,
    ManagementPlanDialogComponent,
    ClientSelectionDialogComponent,
    AddressPrintDialogComponent
  ],
  imports: [
    AppRoutingModule,
    AgGridModule.withComponents([]),
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatListModule,  
    MatExpansionModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ChartsModule
  ],
  exports: [
    MatTableModule,
    MatPaginatorModule,  
    MatExpansionModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  entryComponents: [
    OperationDialogComponent,
    SmsDialogComponent,
    SpotDialogComponent,
    HolidayDialogComponent,
    ExchangeDialogComponent,
    ExchangeEditDialogComponent,
    ContractDetailNoteDialogComponent,
    ConfirmationDialogComponent,
    ExportCsvDialogComponent,
    NationalHolidayDialogComponent,
    BuildingsExportDialogComponent,
    RecruitDialogComponent,
    ImportReportDialogComponent,
    ProfitDialogComponent,
    SkillItemDialogComponent,
    StaffDetailDialogComponent,
    MapDialogComponent,
    ProfitChartComponent,
    ManagementPlanDialogComponent,
    ClientSelectionDialogComponent,
    AddressPrintDialogComponent,
    ExportHistoryDialogComponent
  ],
  providers: [ 
    AuthGuard,
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
  ],

  bootstrap: [ AppComponent ]
})
export class AppModule { }


